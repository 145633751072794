/* SuccessPage.css */

.success-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #28a745;
}

.success-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.success-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.success-container button:hover {
  background-color: #0056b3;
}
