/* SubscriptionPage.css */

.subscription-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 40px;
    color: #555;
    margin-top:20px;
  }
  
  .plans {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .plan {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 30%; /* 세 플랜이 한 줄에 나란히 배치되도록 너비 설정 */
    height: 450px; /* 길이 확장 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left; /* 텍스트 왼쪽 정렬 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .plan input {
    display: none;
  }
  
  .plan label {
    display: block;
    cursor: pointer;
    height: 100%; /* 클릭 영역을 전체 박스로 확장 */
  }
  
  .plan.selected {
    border-color: #007bff;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  }
  
  .plan:hover:not(.disabled) {
    transform: scale(1.05); /* 호버 효과 추가 */
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2); /* 호버 시 박스 그림자 효과 추가 */
  }

  .plan.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .plan label .price {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
    color:white;
  }
  
  .subheader {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .features p {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .features img {
    width: 16px; /* 이미지 너비 조정 */
    height: 16px; /* 이미지 높이 조정 */
    margin-right: 8px;
  }
  
  .subscribe-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .subscribe-button:hover {
    background-color: #0056b3;
  }
  
  .disabled-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }