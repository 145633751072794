/* XXX */
/* =============================================================== */
/* Never change or edit any properties in this file.               */
/* =============================================================== */

/* ROOT */
:root {
    /* ================================================ COLOR PALETTE  */
    --main-bg : #000000;
    /* --card-item-bg: #202528; */
    --card-item-bg: #232323;
    --white: #ffffff;
  
    
  
    /* ================================================ BORDER RADIUS */
    --card-radius: 30px;
  }
  
  /* =============================================================== */
  /* ========================================================= LINK  */
  /* =============================================================== */
  .link {
    color: var(--blue-05);
    padding-bottom: 2px;
  }
  .link.under {
    text-decoration: underline;
  }
  
  a.link:visited {
    color: purple;
  }
  